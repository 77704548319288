import * as req from 'api/actions'
import _ from 'lodash'
import * as c from './constant'

export const changePassword = (payload, callback, errCallback) =>
  req.update(
    c.CHANGE_PASSWORD,
    'api/v1/external/users/change_password',
    payload,
    callback,
    {},
    errCallback
  )

export const changePasswordUsers = (payload, callback, errCallback) =>
  req.update(
    c.CHANGE_PASSWORD,
    'api/v1/external/users/change_password',
    payload,
    callback,
    {},
    errCallback
  )

export const dealerChangePassword = (payload, callback, errCallback) =>
  req.update(
    c.CHANGE_PASSWORD,
    'api/v1/external/users/change_password',
    payload,
    callback,
    {},
    errCallback
  )

export const retailerChangePassword = (payload, callback, errCallback) =>
  req.create(
    c.CHANGE_PASSWORD,
    'api/v1/retailers/change-password',
    payload,
    callback,
    {},
    errCallback
  )

export const changeMpin = (payload, callback, errCallback) =>
  req.update(
    c.CHANGE_MPIN,
    'api/v1/external/users/change_mpin',
    payload,
    callback,
    {},
    errCallback
  )

export const updateProfile = (payload, callback) =>
  req.update(
    c.UPDATE_PROFILE,
    'api/v1/retailers/update-retailer',
    payload,
    callback
  )

export const updatePin = (payload, callback, errCallBack) =>
  req.update(
    c.UPDATE_PIN,
    `api/v1/external/users/my/pin-status`,
    payload,
    callback,
    {},
    errCallBack
  )

export const forgotPin = (payload, callback) =>
  req.update(
    c.FORGOT_PIN,
    'api/v1/external/users/reset-security-pin',
    payload,
    callback
  )

export const getConsent = (payload, callback) =>
  req.list(c.GET_CONSENT, `api/v1/external/users/consents/`, payload, callback)

export const showConsent = (consentId, payload, callback) =>
  req.item(
    c.SHOW_CONSENT,
    `cms/api/v1/consents/${consentId}`,
    payload,
    callback
  )

export const getConsentStatus = (id, callback) =>
  req.item(
    c.CONSENT_STATUS,
    `api/v1/retailers/${id}/consents`,
    {},
    callback,
    ({ data }) => {
      const newData = {}
      _.forOwn(data, (v) => {
        // newData[_.get(v, 'attributes.slug')] = _.get(v, 'attributes.has_agreed')
        newData[_.get(v, 'attributes.slug')] = {
          has_agreed: _.get(v, 'attributes.has_agreed'),
          updated_at: _.get(v, 'attributes.updated_at')
        }
      })
      return newData
    }
  )

export const setThreshold = (retailerId, payload, callback) =>
  req.update(
    c.SET_THRESHOLD,
    `api/v1/retailers/${retailerId}`,
    payload,
    callback
  )

export const updateConsent = (retailerId, payload, callback) =>
  req.create(
    c.UPDATE_CONSENT,
    `api/v1/retailers/${retailerId}/consents`,
    payload,
    callback
  )

export const setOtp = (payload, callback) =>
  req.create(
    c.SET_OTP,
    'external/api/v1/otp/send',
    { ...payload, purpose: 'portal-verification', type: 'sms' },
    callback
  )

export const confirmOtp = (payload, callback, errCallback) =>
  req.create(
    c.CONFIRM_OTP,
    'api/v1/external/otp/verify',
    { ...payload, purpose: 'portal-verification' },
    callback,
    {},
    errCallback
  )

export const inquireMpin = (payload, callback, errCallback) =>
  req.create(
    c.MPIN,
    'api/v1/external/users/my/validate-mpin',
    payload,
    callback,
    {},
    errCallback || null
  )

export const showRetailerUses = (payload, callback) =>
  req.item(c.SHOW_RETAILER_USERS, 'api/v1/retailer-users/my', payload, callback)

export const showCorpAdmin = (payload, callback) =>
  req.item(
    c.SHOW_RETAILER_USERS,
    'api/v1/external/users/my/profile',
    payload,
    callback
  )

export const showRetailer = (retailerId, payload, callback) =>
  req.item(c.SHOW_RETAILER, `api/v1/retailers/${retailerId}`, payload, callback)

export const showDealerUser = (dealerId, payload, callback) =>
  req.item(
    c.SHOW_RETAILER_USERS,
    `api/v1/dealers/${dealerId}`,
    payload,
    callback
  )

export const confirmPassword = (payload, callback, errCallback) =>
  req.create(
    c.CONFIRM_PASS,
    'api/v1/external/users/my/validate-password',
    payload,
    callback,
    {},
    errCallback
  )

export const updateStatus = (payload) => {
  sessionStorage.setItem('profile', JSON.stringify(payload))
  return req.setItem('AUTH/user_profile', payload)
}
