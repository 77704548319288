import React from 'react'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { ToastError } from 'modules/common/components/Toast'
import ButtonAsync from 'components/Button'
import { ModalContext } from 'App'
import { passwordStrength, checkPermissions } from 'helper'
import SetOTP from './SetOTP'
import * as dashboardActions from '../../dashboard/actions'
import * as actions from '../actions'
import * as c from '../constant'

const form = {
  old_password: '',
  new_password: '',
  new_password_confirmation: ''
}

function ChangePassword({
  setOtp,
  profile,
  isLoading,
  roles,
  profileType,
  retailerUser,
  dealerChangePassword
}) {
  const setModal = React.useContext(ModalContext)
  const [formData, setFormData] = React.useState(form)
  const [view, setView] = React.useState()
  const [pending, setPending] = React.useState(false)
  const isKapartnerUser =
    profileType === 'retailers' && _.get(roles, '0', '') === 'kapartner-user'
  const isDealer = profileType === 'dealers'
  const isRetailerUser =
    (profileType === 'retailer_users' &&
      _.get(roles, '0', '') === 'branch-admin') ||
    _.get(roles, '0', '') === 'frontliner' ||
    _.includes(_.get(roles, '0', ''), 'corporate-admin')

  const pwStrength = passwordStrength(formData.new_password || '')

  const handleChangeInput = (key) => ({ target }) => {
    setFormData({
      ...formData,
      [key]: target.value
    })
  }

  const handleOnViewPassword = (key, value) => (e) => {
    e.preventDefault()
    setView({
      [key]: value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setPending(true)
    if (isKapartnerUser) {
      const payload = {
        mobile: _.get(profile, 'mobile_identification_number')
      }
      if (!pwStrength.isValid) {
        ToastError(
          'Password length should be minimum of eight (8) characters and should contain characters from three out of four categories.'
        )
      }
      if (pwStrength.isValid) {
        setOtp(payload, (res) => {
          setModal('set-otp', {
            payload: formData,
            type: 'change-password',
            mobile_identification_number: _.get(
              profile,
              'mobile_identification_number'
            ),
            setFormData,
            form,
            ...res?.res?.data
          })
          setPending(false)
        })
      }
    }

    if (isRetailerUser) {
      const payload = {
        mobile: _.get(retailerUser, 'attributes.mobile')
      }
      if (!pwStrength.isValid) {
        ToastError(
          'Password length should be minimum of eight (8) characters and should contain characters from three out of four categories.'
        )
      }
      if (pwStrength.isValid) {
        setOtp(payload, (res) => {
          setModal('set-otp', {
            payload: { ...payload, ...formData },
            mobile_identification_number: _.get(
              retailerUser,
              'attributes.mobile'
            ),
            type: 'change-password',
            setFormData,
            form,
            ...res?.res?.data
          })
          setPending(false)
        })
      }
    }
    if (isDealer) {
      const payload = {
        mobile: _.get(profile, 'mobile')
      }
      if (!pwStrength.isValid) {
        ToastError(
          'Password length should be minimum of eight (8) characters and should contain characters from three out of four categories.'
        )
      }
      if (pwStrength.isValid) {
        setOtp(payload, (res) => {
          setModal('set-otp', {
            payload: formData,
            type: 'change-password',
            mobile_identification_number: _.get(profile, 'mobile'),
            setFormData,
            form,
            ...res?.res?.data
          })
          setPending(false)
        })
      }
    }
  }

  return (
    <div className="container-fluid h-100">
      <div className="row d-flex align-items-center justify-content-center h-100">
        <div className="col-xl-10 col-lg-10 mt-lg-4 mt-xl-auto mx-auto">
          <h4 className="font-weight-bold mt-0 mb-2">Change Password</h4>
          <p>Create a strong password to secure account</p>
          <form onSubmit={handleSubmit}>
            <div className="form-group mt-4">
              <div className="prepend-form-right">
                <label className="form-label text-primary">
                  Current Password:
                </label>
                <input
                  className="form-control form-custom form-control_bottom"
                  type={_.get(view, 'old_password') ? 'text' : 'password'}
                  placeholder="Current Password"
                  required
                  onChange={handleChangeInput('old_password')}
                  value={_.get(formData, 'old_password') || ''}
                  disabled={!checkPermissions(['can-update-password'])}
                />
                <button
                  type="button"
                  onClick={handleOnViewPassword(
                    'old_password',
                    !_.get(view, 'old_password')
                  )}
                  className="btn btn-default rounded-circle p-0 mr-1"
                >
                  <i
                    className={`fas ${
                      !_.get(view, 'old_password') ? 'fa-eye' : 'fa-eye-slash'
                    }`}
                  />
                </button>
              </div>
            </div>
            <div className="form-group">
              <div className="prepend-form-right">
                <label className="form-label text-primary">New Password:</label>
                <input
                  className="form-control form-custom form-control_bottom"
                  type={_.get(view, 'new_password') ? 'text' : 'password'}
                  placeholder="New Password"
                  required
                  onChange={handleChangeInput('new_password')}
                  value={_.get(formData, 'new_password') || ''}
                  disabled={!checkPermissions(['can-update-password'])}
                />
                <button
                  type="button"
                  onClick={handleOnViewPassword(
                    'new_password',
                    !_.get(view, 'new_password')
                  )}
                  className="btn btn-default rounded-circle p-0 mr-1"
                >
                  <i
                    className={`fas ${
                      !_.get(view, 'new_password') ? 'fa-eye' : 'fa-eye-slash'
                    }`}
                  />
                </button>
              </div>
            </div>
            <div className="form-group">
              <div className="prepend-form-right">
                <label className="form-label text-primary">
                  Retype New Password:
                </label>
                <input
                  className="form-control form-custom form-control_bottom"
                  type={
                    _.get(view, 'new_password_confirmation')
                      ? 'text'
                      : 'password'
                  }
                  required
                  placeholder="New Password"
                  onChange={handleChangeInput('new_password_confirmation')}
                  value={_.get(formData, 'new_password_confirmation') || ''}
                  disabled={!checkPermissions(['can-update-password'])}
                />
                <button
                  type="button"
                  onClick={handleOnViewPassword(
                    'new_password_confirmation',
                    !_.get(view, 'new_password_confirmation')
                  )}
                  className="btn btn-default rounded-circle p-0 mr-1"
                >
                  <i
                    className={`fas ${
                      !_.get(view, 'new_password_confirmation')
                        ? 'fa-eye'
                        : 'fa-eye-slash'
                    }`}
                  />
                </button>
              </div>
              <small className="text-muted">
                Password length should be minimum of eight (8) characters and
                should contain characters from three out of four categories:
              </small>
            </div>
            <div className="form-inline mb-4">
              <div className="form-group mr-2">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    checked={pwStrength.isAlphaNumeric}
                    readOnly
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="passwordAlpha"
                  >
                    Alphanumeric
                  </label>
                </div>
              </div>
              <div className="form-group mr-2">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    checked={pwStrength.hasUpperCase}
                    readOnly
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="passwordUppercase"
                  >
                    Uppercase Letter
                  </label>
                </div>
              </div>
              <div className="form-group mr-2">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    readOnly
                    checked={pwStrength.hasLowerCase}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="passwordLowercase"
                  >
                    Lowercase Letter
                  </label>
                </div>
              </div>
              <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    readOnly
                    checked={pwStrength.hasSpecialCharacter}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="passwordSpecialChar"
                  >
                    Special Characters
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group">
              <ButtonAsync
                type="submit"
                className="btn btn-primary btn-block px-4 position-relative"
                disabled={
                  !pwStrength.isValid ||
                  !checkPermissions(['can-update-password'])
                }
                loading={pending}
              >
                Change Password
              </ButtonAsync>
            </div>
          </form>
        </div>
      </div>
      <SetOTP />
    </div>
  )
}

ChangePassword.propTypes = {
  setOtp: PropTypes.func.isRequired,
  dealerChangePassword: PropTypes.func.isRequired,
  profile: PropTypes.instanceOf(Object).isRequired,
  retailerUser: PropTypes.instanceOf(Object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  roles: PropTypes.instanceOf(Array).isRequired,
  profileType: PropTypes.string.isRequired
}

const mapStateToProps = ({ api }) => ({
  retailerUser: _.get(api, `${c.SHOW_RETAILER_USERS}.item`) || {},
  profile: _.get(api, 'AUTH/user_profile.item') || {},
  isLoading: api.loading.indexOf(c.CHANGE_PASSWORD) > -1,
  profileType: _.get(api, 'AUTH/user_profile.item.profile_type') || '',
  roles: _.get(api, 'AUTH/user_profile.item.roles') || []
})

const enhance = _.flowRight([
  withRouter,
  connect(mapStateToProps, {
    ...dashboardActions,
    ...actions
  })
])

export default enhance(ChangePassword)
