import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import { connect, useSelector } from 'react-redux'
import withModal from 'modules/common/hoc/withModal'
import Button from 'components/Button'
import { ModalContext } from 'App'
import { ToastSuccess } from 'modules/common/components/Toast'
import ReactInputMask from 'react-input-mask'
import * as actions from '../actions'
import * as c from '../constant'

function SetOTP({
  changeMpin,
  payload,
  type,
  changePassword,
  isLoading,
  profile_type,
  changePasswordUsers,
  setFormData,
  form,
  roles,
  retailerChangePassword,
  mobile_identification_number,
  confirmOtp
}) {
  const setModal = React.useContext(ModalContext)
  const [state, setState] = React.useState({})
  const [pending, setPending] = React.useState(false)
  const modalData = useSelector(({ modal }) => modal?.modalData || {})

  const isDealer = profile_type === 'dealers'
  const isKapartnerUser =
    profile_type === 'retailers' && _.get(roles, '0', '') === 'kapartner-user'
  const closeModal = (e) => {
    e.preventDefault()
    setModal({ isOpen: false })
  }

  const reset = () => {
    setPending(false)
    setState({})
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setPending(true)
    if (type === 'change-password') {
      if (isDealer) {
        const newPayload = {
          ...payload
        }
        const otpPayload = {
          mobile: mobile_identification_number,
          otp: _.get(state, 'otp')
        }
        confirmOtp(
          otpPayload,
          () => {
            changePasswordUsers(
              newPayload,
              (response) => {
                ToastSuccess(_.get(response, 'res.data.success[0].detail'))
                setModal({ isOpen: false })
                setFormData(form)
                reset()
              },
              reset
            )
          },
          reset
        )
      }
      if (!isDealer) {
        const newPayload = {
          ...payload
        }
        const otpPayload = {
          mobile: mobile_identification_number,
          otp: _.get(state, 'otp')
        }
        confirmOtp(
          otpPayload,
          () => {
            changePasswordUsers(
              newPayload,
              (response) => {
                ToastSuccess(_.get(response, 'res.data.success[0].detail'))
                setModal({ isOpen: false })
                setFormData(form)
                reset()
              },
              reset
            )
          },
          reset
        )
      }
      if (!isDealer && isKapartnerUser) {
        const newPayload = {
          ...payload
        }
        const otpPayload = {
          mobile: mobile_identification_number,
          otp: _.get(state, 'otp')
        }
        confirmOtp(
          otpPayload,
          () => {
            retailerChangePassword(
              newPayload,
              (response) => {
                ToastSuccess(_.get(response, 'res.data.success[0].detail'))
                setModal({ isOpen: false })
                setFormData(form)
                reset()
              },
              reset
            )
          },
          reset
        )
      }
    }
    if (type !== 'change-password') {
      confirmOtp(
        { mobile: mobile_identification_number, otp: _.get(state, 'otp') },
        () =>
          changeMpin(
            payload,
            (response) => {
              ToastSuccess(_.get(response, 'res.data.success[0].detail'))
              setModal({ isOpen: false })
              setFormData(form)
              reset()
            },
            reset
          ),
        reset
      )
    }
  }
  const handleChangeInput = (key) => ({ target }) => {
    setState({
      ...state,
      [key]: target.value
    })
  }

  return (
    <form className="container-fluid" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <div className="prepend-form-right">
            <label className="form-label">
              Enter the one-time-password we have sent to your SMS number
              +63********{modalData?.sms?.detail?.mobile_end}
            </label>
            <ReactInputMask
              type="text"
              placeholder="6 Digit OTP"
              mask="999999"
              maskChar=""
              className="form-control form-custom form-control_bottom"
              name="otp"
              required
              onChange={handleChangeInput('otp')}
              value={_.get(state, 'otp') || ''}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="mt-3 d-flex align-items-center justify-content-end">
            <Button
              type="submit"
              className="btn btn-primary position-relative font-weight-bold mr-2"
              loading={pending}
            >
              Submit
            </Button>
            <Button
              type="button"
              onClick={closeModal}
              className="btn btn-danger position-relative font-weight-bold ml-2"
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

SetOTP.propTypes = {
  changeMpin: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired,
  retailerChangePassword: PropTypes.func.isRequired,
  changePasswordUsers: PropTypes.func.isRequired,
  confirmOtp: PropTypes.func.isRequired,
  payload: PropTypes.instanceOf(Object).isRequired,
  form: PropTypes.instanceOf(Object).isRequired,
  roles: PropTypes.instanceOf(Array).isRequired,
  type: PropTypes.string.isRequired,
  profile_type: PropTypes.string.isRequired,
  mobile_identification_number: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired
}

const mapStateToProps = ({ api, modal }) => ({
  type: _.get(modal, 'modalData.type'),
  mobile_identification_number:
    _.get(modal, 'modalData.mobile_identification_number') || '',
  setFormData: _.get(modal, 'modalData.setFormData'),
  form: _.get(modal, 'modalData.form'),
  payload: _.get(modal, 'modalData.payload'),
  profile_type: _.get(api, 'AUTH/user_profile.item.profile_type', ''),
  isLoading: api.loading.indexOf(c.CONFIRM_OTP) > -1,
  roles: _.get(api, 'AUTH/user_profile.item.roles') || []
})

const enhance = _.flowRight([
  withModal('set-otp', {
    title: 'OTP Confirmation',
    size: 'modal-sm'
  }),
  withRouter,
  connect(mapStateToProps, actions)
])

export default enhance(SetOTP)
