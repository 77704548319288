import React from 'react'
import ReactInputMask from 'react-input-mask'
import { ToastError } from 'modules/common/components/Toast'
import Button from 'components/Button'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ModalContext } from 'App'
import SetOTP from './SetOTP'
import * as actions from '../actions'
import * as c from '../constant'

const form = {
  old_mpin: '',
  new_mpin: '',
  new_mpin_confirmation: ''
}

function ChangeSecurity({ inquireMpin, setOtp, profile, isLoading }) {
  const [formData, setFormData] = React.useState(form)
  const setModal = React.useContext(ModalContext)
  const [view, setView] = React.useState({
    old_mpin: false,
    new_mpin: false,
    new_mpin_confirmation: false
  })

  const handleChangeInput = (key) => ({ target }) => {
    setFormData({
      ...formData,
      [key]: target.value
    })
  }

  const handleOnViewMPIN = (key, value) => (e) => {
    e.preventDefault()
    setView({
      [key]: value
    })
  }

  const handleSumbit = (e) => {
    e.preventDefault()
    if (formData.new_mpin === formData.old_mpin) {
      ToastError('Your new PIN should not match with your old PIN!')
      setFormData(form)
    } else if (formData.new_mpin !== formData.new_mpin_confirmation) {
      ToastError('Your new PIN and confirmation do not match!')
      setFormData(form)
    } else {
      const payload = {
        ...formData,
        mobile:
          _.get(profile, 'mobile_identification_number') ||
          _.get(profile, 'mobile')
      }
      inquireMpin(
        { mpin: formData.old_mpin },
        () =>
          setOtp(payload, (res) => {
            setModal('set-otp', {
              payload: formData,
              type: 'mpin',
              mobile_identification_number: payload.mobile,
              setFormData,
              ...res?.res?.data
            })
          }),
        () => setFormData(form)
      )
    }
  }

  const checkInput = () => {
    const { old_mpin, new_mpin, new_mpin_confirmation } = formData
    if (
      _.size(old_mpin) === 6 &&
      _.size(new_mpin) === 6 &&
      _.size(new_mpin_confirmation) === 6
    )
      return false
    return true
  }

  return (
    <div className="container-fluid h-100">
      <div className="align-items-center justify-content-center h-100">
        <div className="col-xl-10 col-lg-10 mt-lg-4 mt-xl-auto mx-auto">
          <h4 className="font-weight-bold mt-0 mb-2">Change Security PIN</h4>
          <p className="text-muted">
            Please fill up the fields with required data to process the Change
            Security PIN request.
          </p>
          <form onSubmit={handleSumbit}>
            <div className="form-group mt-4">
              <div className="prepend-form-right">
                <label className="form-label text-primary">Current PIN:</label>
                <ReactInputMask
                  type={_.get(view, 'old_mpin') ? 'text' : 'password'}
                  placeholder="Current 6 Digit Security PIN"
                  mask="999999"
                  maskChar=""
                  className="form-control form-custom form-control_bottom"
                  name="old_mpin"
                  required
                  onChange={handleChangeInput('old_mpin')}
                  value={_.get(formData, 'old_mpin') || ''}
                />
                <button
                  type="button"
                  onClick={handleOnViewMPIN(
                    'old_mpin',
                    !_.get(view, 'old_mpin')
                  )}
                  className="btn btn-default rounded-circle p-0 mr-1"
                >
                  <i
                    className={`fas ${
                      !_.get(view, 'old_mpin') ? 'fa-eye' : 'fa-eye-slash'
                    }`}
                  />
                </button>
              </div>
            </div>
            <div className="form-group">
              <div className="prepend-form-right">
                <label className="form-label text-primary">New PIN:</label>
                <ReactInputMask
                  type={_.get(view, 'new_mpin') ? 'text' : 'password'}
                  placeholder="New 6-Digit PIN"
                  mask="999999"
                  maskChar=""
                  className="form-control form-custom form-control_bottom"
                  name="new_mpin"
                  required
                  onChange={handleChangeInput('new_mpin')}
                  value={_.get(formData, 'new_mpin') || ''}
                />
                <button
                  type="button"
                  onClick={handleOnViewMPIN(
                    'new_mpin',
                    !_.get(view, 'new_mpin')
                  )}
                  className="btn btn-default rounded-circle p-0 mr-1"
                >
                  <i
                    className={`fas ${
                      !_.get(view, 'new_mpin') ? 'fa-eye' : 'fa-eye-slash'
                    }`}
                  />
                </button>
              </div>
            </div>
            <div className="form-group">
              <div className="prepend-form-right">
                <label className="form-label text-primary">
                  Retype New PIN:
                </label>
                <ReactInputMask
                  type={
                    _.get(view, 'new_mpin_confirmation') ? 'text' : 'password'
                  }
                  placeholder="New 6-Digit PIN confirmation"
                  mask="999999"
                  maskChar=""
                  className="form-control form-custom form-control_bottom"
                  name="new_mpin_confirmation"
                  required
                  onChange={handleChangeInput('new_mpin_confirmation')}
                  value={_.get(formData, 'new_mpin_confirmation') || ''}
                />
                <button
                  type="button"
                  onClick={handleOnViewMPIN(
                    'new_mpin_confirmation',
                    !_.get(view, 'new_mpin_confirmation')
                  )}
                  className="btn btn-default rounded-circle p-0 mr-1"
                >
                  <i
                    className={`fas ${
                      !_.get(view, 'new_mpin_confirmation')
                        ? 'fa-eye'
                        : 'fa-eye-slash'
                    }`}
                  />
                </button>
              </div>
            </div>
            <div>
              <p className="text-muted">
                By clicking &quot;Change PIN&quot; button, a One Time Password
                (OTP) will be sent to your SMS number for verification purposes.
              </p>
            </div>
            <div className="form-group">
              <Button
                type="submit"
                className="btn btn-primary position-relative w-100"
                loading={isLoading}
                disabled={checkInput}
              >
                Change Pin
              </Button>
            </div>
          </form>
        </div>
      </div>
      <SetOTP />
    </div>
  )
}

ChangeSecurity.propTypes = {
  inquireMpin: PropTypes.func.isRequired,
  setOtp: PropTypes.func.isRequired,
  profile: PropTypes.instanceOf(Object).isRequired,
  isLoading: PropTypes.bool.isRequired
}

const mapStateToProps = ({ api }) => ({
  profile: _.get(api, 'AUTH/user_profile.item') || {},
  isLoading: api.loading.indexOf(c.MPIN) > -1
})

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)])

export default enhance(ChangeSecurity)
